<template>
    <div class="alliance">
        <div class="allianceName flex-align-center">
            平谷区空间
        </div>
        <div class="spceType">
            <div class="location_L">
                <div class="overview">
                    <div class="overview_headline headline_bg">
                        概览
                    </div>
                    <div class="overview_graphic border_sty flex-column-around">
                        <div class="count_t flex-align-between">
                            <div class="qi_County_num">
                                <div class="overview_title1">学校数量</div>
                                <div class="overview_num1 fontColor1">{{ 110 || 0 }}</div>
                            </div>
                            <div class="school_num">
                                <div class="overview_title1">项目数量</div>
                                <div class="overview_num1 fontColor2">{{ 13 || 0 }}</div>
                            </div>
                        </div>
                        <div class="count_B flex-align-between">
                            <div class="participants_num">
                                <div class="overview_title2">参与人数</div>
                                <div class="overview_num2 fontColor3">
                                    <span>{{ 1015 || 0 }}</span>
                                    <span>人</span>
                                </div>
                            </div>
                            <div class="study_sum_time">
                                <div class="overview_title2">学习总时长</div>
                                <div class="overview_num2 fontColor4">
                                    <span>{{ 61492 || 0 }}</span>
                                    <span>小时</span>
                                </div>
                            </div>
                            <div class="achievement_sum_num">
                                <div class="overview_title2">成果总数量</div>
                                <div class="overview_num2 fontColor5">
                                    <span>{{ 186131 || 0 }}</span>
                                    <span>个</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="Learning_time">
                    <div class="overview_headline headline_bg">
                        各项目学习时长
                    </div>
                    <div class="Learning_time_graphic border_sty" id="Learning_time_graphic" v-if="placeholder_show1"></div>
                    <PlaceholderScreen class="Learning_time_graphic  border_sty" v-else></PlaceholderScreen>
                </div>
            </div>
            <div class="location_C">
                <div class="basic_data">
                    <div class="overview_headline headline_bg2">
                        项目基本数据
                    </div>
                    <div class="Learning_time_graphic">
                        <div class="Learning_time_head_bg Learning_time_list_head">
                            <div class="project_name flex-align-center">项目名称</div>
                            <div class="school_num flex-align-center">学校</div>
                            <div class="people_num flex-align-center">人数</div>
                        </div>
                        <div class="Learning_time_list_box">
                            <div class="Learning_time_list_head Learning_time_list"
                                v-for="(item, index) in Learning_time_list" :key="index">
                                <div class="project_name flex-align-center" @click="gocounty(item.project_name)">{{
                                    item.project_name }}</div>
                                <div class="school_num flex-align-center">{{ item.school_num }}</div>
                                <div class="people_num flex-align-center">{{ item.people_num }}</div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="Item_distribution">
                    <div class="overview_headline headline_bg2">
                        学校项目数量分布
                    </div>
                    <div class="Item_distribution_graphic border_sty" id="Item_distribution_graphic"
                        v-if="placeholder_show2"></div>
                    <PlaceholderScreen class="Item_distribution_graphic  border_sty" v-else></PlaceholderScreen>
                </div>
            </div>
            <div class="location_R">
                <div class="achievement_num">
                    <div class="overview_headline headline_bg">
                        各项目成果数量
                    </div>
                    <div class="achievement_num_graphic border_sty" id="achievement_num_graphic" v-if="placeholder_show3">
                    </div>
                    <PlaceholderScreen class="achievement_num_graphic  border_sty" v-else></PlaceholderScreen>
                </div>
                <div class="item_classification">
                    <div class="overview_headline headline_bg">
                        培训项目类别
                    </div>
                    <div class="item_classification_graphic border_sty" id="item_classification_graphic"
                        v-if="placeholder_show4"></div>
                    <PlaceholderScreen class="achievement_num_graphic  border_sty" v-else></PlaceholderScreen>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PlaceholderScreen from '../../common/placeholderBox/placeholderScreen.vue';
export default {
    //import引入组件才能使用
    components: {
        PlaceholderScreen
    },
    props: {},
    data() {
        return {
            placeholder_show1: true,
            placeholder_show2: true,
            placeholder_show3: true,
            placeholder_show4: true,
            Learning_time_list: [
                {
                    project_name: '平谷区职业学校和商都职业技术学校“青蓝工程”帮扶项目',
                    school_num: '43',
                    people_num: '712',
                },
                {
                    project_name: '国家乡村振兴重点帮扶县教育人才“组团式”帮扶内蒙项目',
                    school_num: '57',
                    people_num: '1217',
                },
                {
                    project_name: '教师研修中心干部教师能力提升线上交流项目',
                    school_num: '6',
                    people_num: '18',
                },
                {
                    project_name: '教育对口支援合作地区干部教师远程培训项目',
                    school_num: '1',
                    people_num: '11',
                },
                {
                    project_name: '英语教学中落实新课标理念策略指导项目',
                    school_num: '27',
                    people_num: '516',
                },
                {
                    project_name: '远程同步课堂、同步教研项目',
                    school_num: '4',
                    people_num: '14',
                },
                {
                    project_name: '结对校线上交流项目',
                    school_num: '30',
                    people_num: '275',
                },
                {
                    project_name: '“课堂教学设计能力提升”专题网络培训项目',
                    school_num: '24',
                    people_num: '360',
                },
                {
                    project_name: '手拉手学校间线上交流项目',
                    school_num: '30',
                    people_num: '404',
                },
            ],
            barimg1: require('../../assets/Screen/alliance/bar1.png'),
            barimg2: require('../../assets/Screen/alliance/bar2.png'),
        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        // 各项目学习时长*1
        Learning_time_graphic() {
            this.$echarts.init(document.getElementById("Learning_time_graphic")).dispose();
            let that = this;
            let myChart = this.$echarts.init(
                document.getElementById("Learning_time_graphic")
            );
            const xData = [];
            this.Learning_time_list.forEach((item) => {
                xData.push(item.project_name);
            })
            const data1 = [21.5, 12.9, 20.8, 41.5, 15.3, 21.7, 21.2, 20.4, 20.5]
            const data2 = [22.3, 12.3, 20.7, 40.6, 16.2, 20.5, 22.3, 20.4, 19]
            const legendData = {
                '区内平均时长': true,
                '全市平均时长': true
            }
            const nameData = Object.keys(legendData);
            myChart.setOption({
                tooltip: {
                },
                legend: {
                    top: 20,
                    left: 'center',
                    show: true,
                    textStyle: {
                        color: '#fff',
                        fontSize: 11
                    },
                },
                grid: {
                    top: '15%',
                    bottom: '5%',
                    left: '4%',
                    right: '4%',
                    containLabel: true
                },
                xAxis: {
                    data: xData,
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#fff',
                            fontSize: '11'
                        }
                    },
                    axisLabel: {
                        rotate: 45,
                        textStyle: {
                            color: '#fff',
                            fontSize: '11'
                        },
                        formatter: function (value) {
                            if (value.length > 6) {
                                return `${value.slice(0, 6)}...`;
                            }
                            return value;
                        }

                    },
                },
                yAxis: [
                    {
                        type: 'value',
                        min: 0,
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#0b296d',
                                width: 1,
                                type: 'solid'
                            },
                            axisTick: {
                                show: false
                            },
                            axisLabel: {
                                textStyle: {
                                    color: '#fff',
                                    fontSize: '11'
                                },
                                formatter: function (value) {
                                    if (value.length > 6) {
                                        return `${value.slice(0, 6)}...`;
                                    }
                                    return value;
                                }

                            },
                        },
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#fff',
                                fontSize: '11'
                            }
                        },
                    },
                    {
                        type: 'value',
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: 'rgba(52,78,130,0.2)',
                                width: 1,
                                type: 'solid'
                            }
                        },
                        axisLabel: {
                            formatter: '{value} %'
                        },
                        axisLine: {
                            show: false
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#fff',
                                fontSize: '11'
                            },
                            formatter: function (value) {
                                if (value.length > 6) {
                                    return `${value.slice(0, 6)}...`;
                                }
                                return value;
                            }

                        },
                    }
                ],
                series: [
                    {
                        name: nameData[0],
                        type: 'bar',
                        barWidth: '22px',
                        itemStyle: {
                            color: new that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: 'rgba(29, 142, 232,1)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(96, 227, 238,1)'
                                }
                            ])
                        },
                        data: data1
                    },
                    {
                        name: nameData[1],
                        type: 'line',
                        yAxisIndex: 1,
                        showAllSymbol: false, // 显示所有图形。
                        symbolSize: 6,
                        symbol: 'circle',
                        lineStyle: {
                            width: 2,
                            color: '#3fb56f'
                        },
                        itemStyle: {
                            color: '#32d1b6',
                            shadowColor: '#f2bb03',
                            shadowBlur: 10,
                            borderColor: '#f2bb03',
                            borderWidth: 2,
                        },
                        data: data2
                    }
                ]
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        // 旗县项目数量分布*2
        Item_distribution_graphic() {
            this.$echarts.init(document.getElementById("Item_distribution_graphic")).dispose();
            let that = this;
            let myChart = this.$echarts.init(
                document.getElementById("Item_distribution_graphic")
            );
            let datas = ['北京市平谷区第六小学', '北京市平谷区第十一小学', '北京市平谷区第五小学', '北京市平谷区北辛庄小学', 
            '平谷区马坊中心小学', '平谷区第三小学', '平谷区刘家店中心小学', '平谷区放光中心小学', '北京市平谷区第五中学', '北京实验学校', '北京市平谷区小树人幼儿园', '北京市平谷区小童星幼儿园', ];
            let colorArr = [];
            let colorArr2 = [
                {
                    color1: 'rgba(46, 149, 121,1)',
                    color2: 'rgba(71, 230, 144,1)',
                },
                {
                    color1: 'rgba(68, 162, 204,1)',
                    color2: 'rgba(131, 254, 248,1)',
                },
                {
                    color1: 'rgba(144, 137, 85,1)',
                    color2: 'rgba(232, 224, 98,1)',
                },
                {
                    color1: 'rgba(8, 87, 168,1)',
                    color2: 'rgba(2, 132, 221,1)',
                },
                {
                    color1: 'rgba(140, 108, 61,1)',
                    color2: 'rgba(250, 128, 11,1)',
                },
                {
                    color1: 'rgba(68, 27, 146,1)',
                    color2: 'rgba(152, 3, 231,1)',
                },
                {
                    color1: 'rgba(95, 43, 111,1)',
                    color2: 'rgba(238, 41, 175,1)',
                },
                {
                    color1: 'rgba(9, 119, 28,1)',
                    color2: 'rgba(5, 169, 10,1)',
                },
                {
                    color1: 'rgba(138, 49, 62,1)',
                    color2: 'rgba(191, 52, 58,1)',
                },
                {
                    color1: 'rgba(101, 95, 73,1)',
                    color2: 'rgba(250, 128, 11,1)',
                },
            ];
            console.log('colorArr.length', colorArr2.length);
            for (let index = 0; index < datas.length; index++) {
                let randomIndex = Math.floor(Math.random() * colorArr2.length);
                colorArr.push(colorArr2[randomIndex])
            }
            myChart.setOption({
                grid: {
                    top: '5%',
                    left: '5%',
                    right: '10%',
                    bottom: '1%',
                    containLabel: true,
                },
                xAxis: {
                    type: 'value',
                    show: false,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: 'rgba(255, 255, 255, 0.2)',
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        fontSize: 11,
                        color: 'rgba(211, 232, 255, 1)',
                        formatter: function (param) {
                            return param + '%';
                        },
                    },
                    min: 0,
                    max: 10,
                    splitLine: {
                        show: false,
                    },
                },
                yAxis: {
                    show: true,
                    type: 'category',
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: 'rgba(255, 255, 255, 0.2)',
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        fontSize: 11,
                        color: '#fff',
                    },
                    data: datas,
                },
                series: [
                    {
                        type: 'bar',
                        barGap: '-65%',
                        label: {
                            normal: {
                                distance: 20,
                                show: true,
                                position: 'right',
                                color: '#fff',
                                fontSize: 11,
                                formatter: function (param) {
                                    return [6, 6, 6, 8, 8, 6, 9, 6, 6, 6, 6, 6, 1][param.dataIndex];
                                },
                            },
                        },
                        barWidth: 5,
                        itemStyle: {
                            normal: {
                                color: 'RGBA(3, 43, 115, 1)',
                            },
                        },
                        z: 0,
                        data: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
                    },
                    {
                        data: [6, 6, 6, 8, 8, 6, 9, 6, 6, 6, 6, 6],
                        type: 'pictorialBar',
                        symbolPosition: 'end',
                        symbol: 'circle',
                        itemStyle: {
                            normal: {
                                show: true,
                                shadowColor: function (params) {
                                    return colorArr[params.dataIndex].color2
                                },
                                shadowBlur: 5,
                                color: function (params) {
                                    return new that.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                                        {
                                            offset: 0,
                                            color: colorArr[params.dataIndex].color1,
                                        },
                                        {
                                            offset: 1,
                                            color: colorArr[params.dataIndex].color2,
                                        },
                                    ])
                                }
                            },
                        },
                        symbolOffset: ['50%', 0],
                        symbolSize: [5, 5],
                        zlevel: 4,
                        silent: true
                    },
                    {
                        data: [6, 6, 6, 8, 8, 6, 9, 6, 6, 6, 6, 6, 1],
                        type: 'pictorialBar',
                        symbolPosition: 'end',
                        symbol: 'circle',
                        itemStyle: {
                            normal: {
                                show: true,
                                color: 'rgba(3, 43, 115,1)'
                            }
                        },
                        symbolOffset: ['50%', 0],
                        symbolSize: [15, 15],
                        zlevel: 3,
                        silent: true
                    },
                    {
                        data: [6, 6, 6, 8, 8, 6, 9, 6, 6, 6, 6, 6,],
                        type: 'pictorialBar',
                        symbolPosition: 'end',
                        symbol: 'circle',
                        itemStyle: {
                            normal: {
                                show: true,
                                color: function (params) {
                                    return colorArr[params.dataIndex].color2
                                }
                            },
                        },
                        symbolOffset: ['50%', 0],
                        symbolSize: [16, 16],
                        zlevel: 2,
                        silent: true
                    },
                    {
                        type: 'bar',
                        barGap: '-100%',
                        barWidth: 5,
                        itemStyle: {
                            normal: {
                                show: true,
                                color: function (params) {
                                    return new that.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                                        {
                                            offset: 0,
                                            color: colorArr[params.dataIndex].color1,
                                        },
                                        {
                                            offset: 1,
                                            color: colorArr[params.dataIndex].color2,
                                        },
                                    ])
                                }
                            },
                        },
                        // max: 10,
                        labelLine: {
                            show: true,
                        },
                        z: 1,
                        data: [6, 6, 6, 8, 8, 6, 9, 6, 6, 6, 6, 6],
                    },
                ],
            });
            myChart.on('click', function (params) {
                if (params.name == '东乌珠穆沁旗') {
                    that.$router.push({
                        path:'/county',
                        query:{
                            areaId:454,
                            county:'东乌珠穆沁旗'
                        }
                    })
                }

            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        // 各项目成果数量*3
        achievement_num_graphic() {
            this.$echarts.init(document.getElementById("achievement_num_graphic")).dispose();
            let that = this;
            let myChart = this.$echarts.init(
                document.getElementById("achievement_num_graphic")
            );
            const xData = [];
            this.Learning_time_list.forEach((item) => {
                xData.push(item.project_name);
            })
            const data = [53, 75, 35, 17, 68, 25, 19, 21, 20];
            const data2 = [49, 73, 38, 16, 66, 23, 22, 20, 21];
            const colorArr2 = ["rgba(202, 135, 1)", "rgba(255, 201, 93)", "rgba(255, 200, 92)"];
            const colorArr = ["rgb(12, 109, 122)", "rgba(0, 210, 243)", "rgb(0, 205, 240)"];
            var color = {
                type: "linear",
                x: 0,
                x2: 1,
                y: 0,
                y2: 0,
                colorStops: [
                    {
                        offset: 0,
                        color: colorArr[0],
                    },
                    {
                        offset: 0.5,
                        color: colorArr[0],
                    },
                    {
                        offset: 0.5,
                        color: colorArr[1],
                    },
                    {
                        offset: 1,
                        color: colorArr[1],
                    },
                ],
            };
            var color2 = {
                type: "linear",
                x: 0,
                x2: 1,
                y: 0,
                y2: 0,
                colorStops: [
                    {
                        offset: 0,
                        color: colorArr2[0],
                    },
                    {
                        offset: 0.5,
                        color: colorArr2[0],
                    },
                    {
                        offset: 0.5,
                        color: colorArr2[1],
                    },
                    {
                        offset: 1,
                        color: colorArr2[1],
                    },
                ],
            };
            var barWidth = 15;
            myChart.setOption({
                tooltip: {
                    trigger: 'axis',
                    formatter: function (params) {
                        var str = params[0].name + ":";
                        params.filter(function (item) {
                            if (item.componentSubType == "bar") {
                                str += "<br/>" + item.seriesName + "：" + item.value;
                            }
                        });
                        return str;
                    },
                },
                // legend: {
                //     top: 20,
                //     left: 'center',
                //     show: true,
                //     textStyle: {
                //         color: '#fff',
                //         fontSize: 11
                //     },
                //     data: [
                //         { name: '区内平均时长', itemStyle: { color: 'red' } },
                //         { name: '全市平均时长', itemStyle: { color: 'blue' } }
                //     ]
                // },
                //图表大小位置限制
                grid: {
                    top: '15%',
                    bottom: '5%',
                    left: '4%',
                    right: '4%',
                    containLabel: true
                },
                xAxis: {
                    data: xData,
                    //坐标轴
                    axisLine: {
                        show: true,
                        lineStyle: {
                            width: 1,
                            color: '#214776'
                        },
                        textStyle: {
                            color: '#fff',
                            fontSize: '11'
                        }
                    },
                    type: 'category',
                    axisLabel: {
                        rotate: 45,
                        textStyle: {
                            color: '#fff',
                            fontWeight: 500,
                            fontSize: '11'
                        },
                        formatter: function (value) {
                            if (value.length > 6) {
                                return `${value.slice(0, 6)}...`;
                            }
                            return value;
                        }
                    },
                    axisTick: {
                        textStyle: {
                            color: '#fff',
                            fontSize: '11'
                        },
                        show: false,
                    },
                    axisLine: {
                        //坐标轴轴线相关设置。数学上的x轴
                        show: true,
                        lineStyle: {
                            type: 'solid',
                            color: '#145882',
                        },
                    },
                },
                yAxis: {
                    // name: '项目成果数量',
                    nameTextStyle: {
                        color: '#DEDEDE',
                        fontSize: 12,
                        padding: 10,
                    },
                    min: 0,//最小
                    max: 100,//最大
                    type: 'value',
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dashed',//线的类型 虚线0
                            opacity: 0.2//透明度
                        },
                    },
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        show: false,
                    },
                    //坐标值标注
                    axisLabel: {
                        show: true,
                        textStyle: {
                            color: '#fff',
                        }
                    },
                },
                series: [
                    //中
                    {
                        z: 1,
                        name: '区内平均时长',
                        type: "bar",
                        barWidth: barWidth,
                        barGap: "0%",
                        data: data,
                        itemStyle: {
                            normal: {
                                color: color,
                                //柱形图上方标题
                                label: {
                                    show: false,
                                    position: 'top',
                                    textStyle: {
                                        color: 'rgb(1, 255, 250)',
                                        fontSize: 8
                                    }
                                },
                            }
                        },
                    },
                    //下
                    {
                        z: 2,
                        name: '区内平均时长',
                        type: "pictorialBar",
                        data: data.map(item => item + 90),
                        symbol: "diamond",
                        symbolOffset: ["-75%", "50%"],
                        symbolSize: [barWidth, 10],
                        itemStyle: {
                            normal: {
                                color: color
                            },
                        },
                        tooltip: {
                            show: false,
                        },
                    },
                    //上
                    {
                        z: 3,
                        name: '区内平均时长',
                        type: "pictorialBar",
                        symbolPosition: "end",
                        data: data,
                        symbol: "diamond",
                        symbolOffset: ["-70%", "-65%"],
                        symbolSize: [barWidth, (10 * (barWidth)) / barWidth],
                        itemStyle: {
                            normal: {
                                borderWidth: 2,
                                color: colorArr[2]
                                // color:'red'
                            },
                        },
                        tooltip: {
                            show: false,
                        },
                    },
                    {
                        z: 1,
                        name: '全市平均时长',
                        type: "bar",
                        barWidth: barWidth,
                        barGap: "50%",
                        data: data2,
                        itemStyle: {
                            normal: {
                                color: color2,
                                //柱形图上方标题
                                label: {
                                    show: false,
                                    position: 'top',
                                    textStyle: {
                                        color: 'rgb(2, 157, 246)',
                                        fontSize: 8
                                    }
                                },
                            }
                        },
                    },
                    {
                        z: 2,
                        name: '全市平均时长',
                        type: "pictorialBar",
                        data: data2.map(item => item + 90),
                        symbol: "diamond",
                        symbolOffset: ["75%", "50%"],
                        symbolSize: [barWidth, 10],
                        itemStyle: {
                            normal: {
                                color: color2
                            },
                        },
                        tooltip: {
                            show: false,
                        },
                    },
                    {
                        z: 3,
                        name: '全市平均时长',
                        type: "pictorialBar",
                        symbolPosition: "end",
                        data: data2,
                        symbol: "diamond",
                        symbolOffset: ["70%", "-64%"],
                        symbolSize: [barWidth + 1, (10 * (barWidth)) / barWidth],
                        itemStyle: {
                            normal: {
                                borderWidth: 2,
                                color: colorArr2[2]
                                // color:'red'
                            },
                        },
                        tooltip: {
                            show: false,
                        },
                    },
                ],
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        // 培训项目类别*4
        item_classification_graphic() {
            this.$echarts.init(document.getElementById("item_classification_graphic")).dispose();
            let that = this;
            let myChart = this.$echarts.init(
                document.getElementById("item_classification_graphic")
            );
            let datas = [352, 258, 126, 105, 77, 68, 29];
            myChart.setOption({
                grid: {
                    // top: '15%',
                    bottom: '2%',
                    left: '14%',
                    right: '10%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        rotate: 45,
                        textStyle: {
                            color: '#fff',
                            fontSize: '11'
                        },
                        formatter: function (value) {
                            if (value.length > 6) {
                                return `${value.slice(0, 8)}...`;
                            }
                            return value;
                        }
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#304869',
                            fontSize: '11'
                        }
                    },
                    data: ['新教师入职培训', '青年教师助力培训', '骨干教师提升培训', '教师培训者团队研修', '管理者任职培训', '管理者提高培训', '管理者高级研修']
                },
                yAxis: {
                    type: 'value',
                    max: 500,
                    axisLabel: {
                        show: false,
                    },
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        show: false,
                    }
                },
                series: [
                    {
                        data: datas,
                        type: 'bar',
                        barWidth: 3,
                        label: {
                            show: true,
                            position: 'top',
                            color: '#fff',
                            formatter: function (params) {
                                return params.value == Math.max(...datas) ? ['{img2|' + params.value + '}'] : ['{img1|' + params.value + '}'];

                            },
                            rich: {
                                img1: {
                                    height: 60, // 自定义图片高度
                                    width: 60,  // 自定义图片宽度
                                    backgroundColor: {
                                        image: that.barimg1 // 设置自定义图片路径
                                    },
                                    color: "#fff",
                                    align: 'center'
                                },
                                img2: {
                                    height: 80, // 自定义图片高度
                                    width: 80,  // 自定义图片宽度
                                    backgroundColor: {
                                        image: that.barimg2 // 设置自定义图片路径
                                    },
                                    color: "#fff",
                                    align: 'center'
                                },
                            }
                        },
                        itemStyle: {
                            color: function (params) {
                                if (params.value == Math.max(...datas)) {
                                    return new that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                        {
                                            offset: 0,
                                            color: 'rgba(189, 145, 42,0.2)'
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(189, 145, 42,1)'
                                        }
                                    ])
                                } else {
                                    return new that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                        {
                                            offset: 0,
                                            color: 'rgba(13, 151, 204,0.2)'
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(13, 151, 204,1)'
                                        }
                                    ])
                                }
                            }
                        },


                    }
                ]
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        //跳转
        gocounty(project_name) {
            window.open(`https://dctest.lezhireading.com/#/leader_data?projectId=425&projectName=${project_name}&token=${sessionStorage.getItem('token')}&roleId=124`)
            // let text = this.$router.resolve({
            //     path: "/county",
            //     query: {
            //         areaId: 446,
            //         county: '扎赉特旗',
            //     },
            // });
            // window.open(text.href, "_blank");
        }
    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {
        // 各项目学习时长*1
        this.Learning_time_graphic();
        // 旗县项目数量分布*2
        this.Item_distribution_graphic();
        // 各项目成果数量*3
        this.achievement_num_graphic();
        // 培训项目类别*4
        this.item_classification_graphic();
    },
    beforeCreate() { },//生命周期：创建之前
    beforeMount() { },//生命周期：挂载之前
    beforeUpdate() { },//生命周期：更新之前
    updated() { },//生命周期：更新之后
    beforeDestroy() { },//生命周期：销毁之前
    destroyed() { },//生命周期：销毁完成
    activated() { },//如果页面有keep-alive缓存功能，这个函数会触发执行
}
</script>
<style scoped lang="less">
@import "./Less/alliance.less";
</style>